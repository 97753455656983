@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.navbar-item {
  @apply hover:bg-gray-800 hover:cursor-pointer px-4 py-2 rounded-xl;
}
